import * as React from "react"
import { WebPage } from "kiwi-bundle"
import * as Recipes from "dropin-recipes"
import router from "../router"
import GoogleMaps from "../components/GoogleMaps"
import { linesStore } from "../stores/lines"

interface Params {
  recipe: Recipes.RecipeId
  document: Recipes.DocumentId
}

export default class Document extends WebPage<Params> {
  state = { type: null, options: null, error: null }
  private token?: string
  private onPrint?: () => void

  constructor(props) {
    super(props)

    const urlParams = router.getParametersAsObject([ "t", "token", "f" ])

    // Token
    if(typeof urlParams.t !== "undefined") {
      this.token = urlParams.t[0]
    } else if(typeof urlParams.token !== "undefined") {
      this.token = urlParams.token[0]
    }

    // Filters
    let filters = "{}"
    if(typeof urlParams.f !== "undefined") {
      filters = urlParams.f[0]
    } else if(typeof urlParams.filters !== "undefined") {
      filters = urlParams.filters[0]
    }

    // Request
    Recipes.DROPinAPI.getDocument(this.params.recipe, this.params.document, { t: this.token, f: filters })
      .then(document => {
        linesStore.setItems(document.lines)
        this.setState({
          type: document.type,
          options: document.options,
        })
      })
      .catch(error => {
        this.setState({ error: error.message })
      })
  }

  private handleMessage = ((message: any) => {
    switch(message.data.type) {
      case "filters":
        if(typeof message.data.filters !== "string") {
          message.data.filters = JSON.stringify(message.data.filters)
        }
        this.updateLines(message.data.filters)
        break
      case "print":
        if(typeof this.onPrint !== "undefined") {
          this.onPrint()
        }
        break
    }
  }).bind(this)

  componentDidMount() {
    window.addEventListener("message", this.handleMessage)
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage, false)
  }

  private updateLines(filters: string) {
    Recipes.DROPinAPI.getDocumentLines(this.params.recipe, this.params.document, { t: this.token, f: filters })
      .then(lines => {
        linesStore.setItems(lines)
      })
  }

  private generateDocument() {
    if(this.state.error !== null) {
      return <div>Erreur renvoyée par le serveur : {this.state.error}</div>
    }

    if(this.state.type === null) {
      return null
    }

    if(this.state.options !== null) {
      if(this.state.type === Recipes.DocumentType.GoogleMaps) {
        return <GoogleMaps
          options={this.state.options}
          defineActionOnPrint={action => { this.onPrint = action }}
        />
      }
    }

    return <div>Erreur lors du chargement du document</div>
  }

  render() {
    return <div id="page-document" style={{ height: "100%", }}>
      {this.generateDocument()}
    </div>
  }

}
