interface InternalStorage {
  setItem: (key: string, value: string) => void
  getItem: (key: string) => string|null
  removeItem: (key: string) => void
}

class AppStorage {

  private internalStorage: InternalStorage

  constructor(internalStorage: InternalStorage) {
    this.internalStorage = internalStorage
  }

  setItem(key: string, value: string) {
    this.internalStorage.setItem(key, value)
  }

  getItem(key: string) {
    return this.internalStorage.getItem(key)
  }

  removeItem(key: string) {
    this.internalStorage.removeItem(key)
  }

  hasItem(key: string) {
    return this.getItem(key) !== null
  }

}

export default AppStorage
