import * as React from "react"

interface Props {
  outsideClickHandler: (event) => void
}

export default class OutsideClickWrapper extends React.PureComponent<Props> {
  wrapperNode: any

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside.bind(this))
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside.bind(this))
  }

  handleClickOutside(event) {
    if(this.wrapperNode && !this.wrapperNode.contains(event.target)) {
      this.props.outsideClickHandler(event)
    }
  }

  render() {
    return <div
      ref={node => { this.wrapperNode = node }}
      children={this.props.children}
    />
  }
}
