import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import "./styles/Footer.scss"

export default class Footer extends WebComponent {
  render() {
    return <footer className="footer"/>
  }
}
