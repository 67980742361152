import { Output } from "dropin-recipes"

export type BaseEntityId = string

abstract class BaseEntity implements Output<BaseEntityId> {
  id: string

  updatedAt: Date
  createdAt: Date

  constructor(id: string, createdAt: Date, updatedAt: Date) {
    this.id = id

    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }
}

export default BaseEntity
