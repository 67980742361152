import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import "./styles/Form.scss"

interface Props {
  className?: string,
  method: string,
  action: string,
  title: string,
  titleClass?: string,
  onSubmit?: (e) => void
}

export default class Form extends WebComponent<Props> {
  render() {
    const { className = "", method, action, title, titleClass = "", onSubmit } = this.props

    return (
      <form className={"form " + className} method={method} action={action} onSubmit={onSubmit}>
        <h2 className={"title form__title " + titleClass } children={title}/>
        {this.props.children}
      </form>
    )
  }
}
