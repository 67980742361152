import * as React from "react"
import { WebComponent } from "kiwi-bundle"

interface Props {
  className?: string
  selected?: boolean
  title: string
  clickHandler?: (e) => void
}

interface State {

}

export default class SidebarMenuSubSection extends WebComponent<Props, State> {
  constructor(props) {
    super(props)
  }

  render() {
    const { 
      className = "", 
      title, 
      clickHandler = () => {}, 
      selected = false 
    } = this.props

    return (
      <div 
        className={ 
          "sidebar__menu-item sidebar__menu-item_sub-section " 
          + className 
          + " " 
          + (selected ? "sidebar__menu-item_sub-section_active" : "") 
        }  
        onClick={ clickHandler }>
        { title }
      </div>
    )
  }
}
