import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import "./styles/Header.scss"

const logo = require("../../../assets/logoBeta.png")

interface Props {

}

export default class Header extends WebComponent<Props> {
  render() {
    return (
      <header className="header row row_centered">
        <h1>
          <a href="/">
            <img src={logo} alt="Dropin" className="header__logo"/>
          </a>
        </h1>
      </header>
    )
  }
}
