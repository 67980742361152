import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import Icon, { IconType } from "../Icon"
import { AppNotification } from "../../App"

import HeaderNavItem from "./HeaderNavItem"
import HeaderDropdownNotifs from "../Dropdown/HeaderDropdownNotifs"

interface Props {
  hasNew: boolean
  notifications?: AppNotification[]
}

export default class HeaderNavItemNotifs extends WebComponent<Props> {
  render() {
    const { hasNew, notifications } = this.props

    return (
      <HeaderNavItem hasDropdown={ true }>
        <a className={"header__nav-link " + (hasNew ? "header__nav-link_new" : "") }>
          <Icon
            type={ IconType.notification }
            className="header__nav-icon"
            width="22"
            height="22"
          />
        </a>
        <HeaderDropdownNotifs notifications={notifications}/>
      </HeaderNavItem>
    )
  }
}
