import { i18nData, Language as l } from "dropin-recipes"

const registerStrings = i18nData({

  step1: {
    [l.ENGLISH]: "Step 1 - define your credentials",
    [l.FRENCH]: "Étape 1 - définir vos identifiants",
  },

  step2: {
    [l.ENGLISH]: "Step 2 - validate your email",
    [l.FRENCH]: "Étape 2 - valider votre e-mail",
  },

  step3: {
    [l.ENGLISH]: "Almost completed!",
    [l.FRENCH]: "Presque terminé !",
  },

  invitationCode: {
    [l.ENGLISH]: "Invitation code",
    [l.FRENCH]: "Code d'invitation",
  },

  GDPR: {
    [l.ENGLISH]: "GDPR",
    [l.FRENCH]: "RGPD",
  },

  terms: {
    [l.ENGLISH]: "I accept the terms and conditions of use",
    [l.FRENCH]: "J'accepte les conditions d'utilisation",
  },

  createMyAccount: {
    [l.ENGLISH]: "Create an account",
    [l.FRENCH]: "Créer mon compte",
  },

  emailSent: {
    [l.ENGLISH]: "We have just sent a confirmation email to",
    [l.FRENCH]: "Nous venons d'envoyer un email de confirmation à",
  },

  emailDescription: {
    [l.ENGLISH]: "Just click on the message link and you can start using DROP'in!",
    [l.FRENCH]: "Il vous suffit de cliquer sur le lien du message et vous pourrez commencer à utiliser DROP'in !",
  },

  emailNotReceived: {
    [l.ENGLISH]: "Still no reception?",
    [l.FRENCH]: "Toujours rien reçu ?",
  },

  emailResend: {
    [l.ENGLISH]: "Send back the confirmation email",
    [l.FRENCH]: "Renvoyer l'email de confirmation",
  },

  haveAnAccount: {
    [l.ENGLISH]: "You have an account?",
    [l.FRENCH]: "Vous possédez déjà un compte ?",
  },

  login: {
    [l.ENGLISH]: "Se connecter !",
    [l.FRENCH]: "Se connecter !",
  },
})

export default registerStrings
