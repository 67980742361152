import { i18nData, Language as l, NameArticle } from "dropin-recipes"

const userStrings = i18nData({

  user: {
    [l.ENGLISH]: { one: "User", many: "Users" },
    [l.FRENCH]: { article: NameArticle.MASCULINE, one: "Utilisateur", many: "Utilisateurs" },
  },

  email: {
    [l.ENGLISH]: { one: "E-mail address", many: "E-mail addresses" },
    [l.FRENCH]: { article: NameArticle.FEMININE, one: "Adresse e-mail", many: "Adresses e-mail" },
  },

  password: {
    [l.ENGLISH]: { one: "Password", many: "Passwords" },
    [l.FRENCH]: { article: NameArticle.MASCULINE, one: "Mot de passe", many: "Mots de passe" },
  },

  register: {
    [l.ENGLISH]: "Sign up",
    [l.FRENCH]: "Inscription",
  },

  login: {
    [l.ENGLISH]: "Sign in",
    [l.FRENCH]: "Connexion",
  },

})

export default userStrings
