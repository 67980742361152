import * as React from "react"
import { WebComponent } from "kiwi-bundle"
import { i18n } from "dropin-recipes"

import FormGroup from "./FormGroup"
import loginStrings from "../../i18n/login"

const googleLogo = require("../../../assets/google.png")
const facebookLogo = require("../../../assets/facebook.png")

export default class FormSocialLogin extends WebComponent {
  render() {
    return (
      <React.Fragment>
        <p className="text text_center text_xs text_primary form__text form__text_first">
          {i18n(loginStrings.useSocials)}
        </p>

        <FormGroup className="form__social-login">
          <a href="#" className="form__social-login-link form__social-login-link_first">
            <img src={googleLogo} alt="Google" className="form__social-login-logo form__social-login-logo_google"/>
          </a>

          <a href="#" className="form__social-login-link">
            <img src={facebookLogo} alt="Facebook" className="form__social-login-logo form__social-login-logo_facebook"/>
          </a>
        </FormGroup>
      </React.Fragment>
    )
  }
}
