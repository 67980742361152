import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import "./styles/Form.scss"

interface Props {
  className?: string,
}

export default class FormError extends WebComponent<Props> {
  render() {
    const { className = "" } = this.props

    return <div className={"form__error " + className} children={this.props.children}/>
  }
}
