import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import "./FlashMessage.scss"

interface Props {
  className?: string
  content: FlashMessageContent
  position?: FlashMessagePosition
}

interface State {
  closed: boolean
}

export enum FlashMessagePosition {
  topLeft = "top-left",
  topRight = "top-right",
  bottomLeft = "bottom-left",
  bottomRight = "bottom-right",
}

export interface FlashMessageContent {
  title: string
  message: string
  actionLinks: Array<FlashMessageActionLink>
}

export interface FlashMessageActionLink {
  title: string
  clickHandler: (e) => void
}

export default class FlashMessage extends WebComponent<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      closed: false
    }
  }

  handleClickOnClose(e) {
    this.setState({
      closed: true
    })
  }

  render() {
    const { className = "", content, position = FlashMessagePosition.topLeft  } = this.props

    if(this.state.closed) {
      return null
    }

    return (
      <div className={ "flash-message flash-message_" + position.toString() + " " + className }>
        <a
          className="flash-message__close"
          onClick={ (e) => { this.handleClickOnClose(e) } }
        >
          X
        </a>

        <h4 className="flash-message__title">
          { content.title }
        </h4>

        <div className="flash-message__text">
          { content.message }
        </div>

        <div className="flash-message__links-container">
          { content.actionLinks.map((link, i) => {
            return (
              <a className="flash-message__link" key={ i } onClick={ link.clickHandler }>
                { link.title }
              </a>
            )
          }) }
        </div>
      </div>
    )
  }
}
