import * as React from "react"
import { WebComponent, IObservableValue } from "kiwi-bundle"
import { i18n } from "dropin-recipes"

import FormGroup from "./FormGroup"
import Input from "./Input"
import Icon from "./Icon"

import userStrings from "../../i18n/user"
import loginStrings from "../../i18n/login"

interface Props {
  showRecoverPasswordLink?: boolean
  enablePasswordShowHelper?: boolean
  recoverPasswordLinkClickHandler?: (e) => void
  value: IObservableValue<string>
  onValueChange?: (value: string) => void
}

interface State {
  passwordVisibility: string
}

export default class InputPassword extends WebComponent<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      passwordVisibility: "hidden"
    }

    this.changePasswordVisibility = this.changePasswordVisibility.bind(this)
  }

  changePasswordVisibility() {
    this.setState((prevState, props) => {
      let newVisibility = "visible"

      if (prevState.passwordVisibility === "visible") {
        newVisibility = "hidden"
      }

      return {
        passwordVisibility: newVisibility
      }
    })
  }

  render() {
    const {
      showRecoverPasswordLink,
      enablePasswordShowHelper,
      recoverPasswordLinkClickHandler,
      value,
      onValueChange,
    } = this.props

    let inputType = this.state.passwordVisibility === "hidden" ? "password" : "text"

    let input = <Input
      type={inputType}
      name="password"
      value={value}
      onValueChange={onValueChange}
      placeholder={i18n(userStrings.password)}
    />

    return (
      <FormGroup>
        { enablePasswordShowHelper ? (
          <div className="form__input-group">
            { input }
            <div className="form__input-group-append">
              <a className="form__input-group-append-text" onClick={this.changePasswordVisibility}>
                <Icon type="eye" size="xs"/>
              </a>
            </div>
          </div>
        ) : (
          input
        ) }

        { showRecoverPasswordLink &&
          <a className="form__recover-password-link" onClick={recoverPasswordLinkClickHandler}>
            {i18n(loginStrings.forgottenPassword)}
          </a>
        }
      </FormGroup>
    )
  }
}
