import * as React from "react"

export default function UserElement(props) {
  return (
    <span className="dropdown__list-item-time">
      <i className="dropdown__list-item-icon dropdown__list-item-icon_top dropdown__list-item-icon_group"></i>
      {props.text}
    </span>
  )
}
