import { SelectorCondition, CollectionRecipeFields } from "dropin-recipes"
import { observable, action } from "kiwi-bundle"

class LinesStore {
  @observable selectors: SelectorCondition[] = []
  @observable sortColumn: string
  @observable sortReverse = false
  @observable fields: CollectionRecipeFields = {}
  @observable columns: string[] = []
  @observable items: any[] = []

  @action
  setFields(fields: CollectionRecipeFields) {
    this.fields = fields
  }

  @action
  setItems(items, selectors: SelectorCondition[] = []) {
    this.items = items
    this.selectors = selectors
  }

  @action
  resetItems() {
    this.items = []
  }
}

export const linesStore = new LinesStore()
