import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import OutsideClickWrapper from "../../OutsideClickWrapper"

interface Props {
  hasDropdown?: boolean
}

interface State {
  showDropdown: boolean
}

export default class HeaderNavItem extends WebComponent<Props, State> {
  state = {
    showDropdown: false
  }

  handleClick() {
    if(this.props.hasDropdown) {
      this.setState((previousState) => ({
        showDropdown: !previousState.showDropdown
      }))
    }
  }

  handleClickOutside() {
    if(this.state.showDropdown) {
      this.setState({ showDropdown: false })
    }
  }

  render() {
    const { hasDropdown = false } = this.props
    return (
      <OutsideClickWrapper outsideClickHandler={this.handleClickOutside.bind(this)}>
        <li
          className={
            "header__nav-item "
            + (hasDropdown ? "header__nav-item_dropdown " : " ")
            + (this.state.showDropdown ? "header__nav-item_dropdown-show" : "")
          }
          onClick={this.handleClick.bind(this)}
          children={this.props.children}
        />
      </OutsideClickWrapper>
    )
  }
}
