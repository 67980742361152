import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import ToolbarDropdown from "./ToolbarDropdown"

interface Props {

}

interface State {
  selectedItem?: GridToolbarDropdownItemId
}

enum GridToolbarDropdownItemId {
  list = "list",
  grid = "grid",
  calendar = "calendar",
  kanban = "kanban",
} 

interface GridToolbarDropdownItem {
  id: GridToolbarDropdownItemId,
  title: string,
  handler: (e) => void,
}

export default class GridToolbarDropdown extends WebComponent<Props, State> {
  items: Array<GridToolbarDropdownItem>

  constructor(props) {
    super(props)

    this.state = {
      selectedItem: GridToolbarDropdownItemId.list
    }

    this.items = [{
      id: GridToolbarDropdownItemId.list,
      handler: this.handleClickOnList,
      title: "Liste"
    }, {
      id: GridToolbarDropdownItemId.grid,
      handler: this.handleClickOnGrid,
      title: "Grille"
    }, {
      id: GridToolbarDropdownItemId.calendar,
      handler: this.handleClickOnCalendar,
      title: "Agenda"
    }, {
      id: GridToolbarDropdownItemId.kanban,
      handler: this.handleClickOnKanban,
      title: "Kanban"
    }]
  }

  handleClickOnList(e) {
    console.log("list")
  }

  handleClickOnGrid(e) {
    console.log("grid")
  }

  handleClickOnCalendar(e) {
    console.log("calendar")
  }

  handleClickOnKanban(e) {
    console.log("kanban")
  }

  handleClickOnItem(e: any, item: GridToolbarDropdownItem) {
    this.setState({
      selectedItem: item.id
    })

    item.handler(e)
  }

  render() {
    return (
      <ToolbarDropdown>
        <ul className="dropdown__list">
          { this.items.map((item) => {
            return (
              <li key={ item.id } className="dropdown__list-item dropdown__list-item_borderless">
                <a
                  onClick={ (e) => { this.handleClickOnItem(e, item) }}
                  className={ "dropdown__list-item-text dropdown__list-item-link " + (this.state.selectedItem === item.id ? "dropdown__list-item-link_selected" : "") }>
                  <i className={ "dropdown__list-item-icon dropdown__list-item-icon_" + item.id }></i>
                  { item.title }
                </a>
              </li>
            )
          })}
        </ul>
      </ToolbarDropdown>
    )
  }
}
