import * as React from "react"
import { WebComponent } from "kiwi-bundle"
import { DROPinAPI } from "dropin-recipes"
import * as H from "history"

import Sidebar from "./authApp/Sidebar/Sidebar"
import Header from "./authApp/Header"

import * as router from "../router"
import FlashMessage, { FlashMessageContent, FlashMessagePosition } from "./authApp/FlashMessage/FlashMessage"

import Auth from "../core/Auth"
import AppStorage from "../core/AppStorage"
import Company from "../core/Company"

import { appStore } from "../stores/app"

import "./App.scss"


interface Props {
  history: H.History<any>
}

interface State {
  notifications: Array<AppNotification>,
  user: User,
  flashMessage: FlashMessageContent
}

export interface AppNotification {
  content: string
  author: string
  id: string
}

export interface User {
  name: string,
  id: string
}

export default class App extends WebComponent<Props, State> {
  notifications: AppNotification[]
  auth: Auth

  constructor(props) {
    super(props)

    this.state = {
      flashMessage: {
        title: "Le commerce a bien été enregistré !",
        message: "Vous venez d’ajouter un élément dans la Collection n°1",
        actionLinks: [{
          title: "Modifier",
          clickHandler: (e) => { console.log("Modifier") }
        }, {
          title: "Annuler",
          clickHandler: (e) => { console.log("Annuler") }
        }]
      },

      user: {
        name: "Bar bar",
        id: "Soo"
      },

      notifications: [{
        id: "test1",
        author: "Alex le 26/06/2019",
        content: "Ajout d’un élément dans “Commerce”",
      }, {
        id: "test2",
        author: "Alex le 26/06/2019",
        content: "Ajout d’un élément dans “Commerce”",
      }, {
        id: "test3",
        author: "Alex le 26/06/2019",
        content: "Ajout d’un élément dans “Commerce”",
      }, {
        id: "test4",
        author: "Alex le 26/06/2019",
        content: "Ajout d’un élément dans “Commerce”",
      }, {
        id: "test5",
        author: "Alex le 26/06/2019",
        content: "Ajout d’un élément dans “Commerce”",
      }]
    }

    this.auth = new Auth(new AppStorage(localStorage))
  }

  handleLogout() {
    this.auth.unauthenticateUser()
    this.props.history.push(router.PAGES.LOGIN)
  }

  componentDidMount() {
    DROPinAPI.setToken(this.auth.getToken())

    DROPinAPI.getCompanies().then((companies) => {
      let appCompanies: Company[] = []

      companies.forEach((company) => {
        appCompanies.push(new Company(
          company.id,
          company.name,
          company.slug,
          company.createdAt,
          company.updatedAt 
        ))
      })

      appStore.setCompanies(appCompanies)
    })
  }

  render() {
    return (
      <div className="app">
        <Sidebar store={appStore}/>

        <main className="app__content">
          <Header
            hasNewNotifications={ true}
            notifications={this.state.notifications}
            user={this.state.user}
            logoutHandler= {() => { this.handleLogout() }}
          />
          {this.props.children}
        </main>

        <FlashMessage
          content={this.state.flashMessage}
          position={FlashMessagePosition.bottomRight}
        />
      </div>
    )
  }
}
