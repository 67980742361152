import * as React from "react"
import { WebComponent } from "kiwi-bundle"
import ListRow from "./ListRow"
import ListCell from "./ListCell"
import { linesStore } from "../../../stores/lines"
import "./List.scss"

interface Props {
  className?: string,
  columns: string[],
  selectable?: boolean,
}

export interface ListHeadingItem {
  id: string
  title: string
  sorting?: boolean
}

export interface ListItem {
  title: string
}

export default class List extends WebComponent<Props> {

  handleSorting(orderKey: string) {
    // Todo
    console.log(orderKey)
  }

  render() {
    const { className = "", columns, selectable = false } = this.props

    const headingCells = columns.map((fieldId, index) => {
      const field = linesStore.fields[fieldId]
      return (
        <ListCell
          key={fieldId}
          heading={true}
          order={true}
          handleOrder={this.handleSorting.bind(this)}
          children={field.name}
        />
      )
    })

    if(selectable) {
      headingCells.unshift(<ListCell key="head" heading={true} order={false} selectorCell={true}/>)
    }

    return <div className={`list list_${columns.length + 1}cols ${className}`}>
      <ListRow>{headingCells}</ListRow>
      {linesStore.items.map((line, rowIndex) => <ListRow shadowed={true} key={line.id}>
        {selectable && <ListCell selectorCell={true}>
            <input type="checkbox" name=""/>
        </ListCell>}
        {columns.map((fieldId, cellIndex) => <ListCell
          key={cellIndex}
          children={line.data[fieldId]}
        />)}
      </ListRow>)}
    </div>
  }
}
