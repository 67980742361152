import * as React from "react"
import { Marker, InfoWindow } from "react-google-maps"
import { hardcodedLaClocheHtml } from "./GoogleMapsPrintable"

interface Props {
  line: any
  setPrevious: (previous: GoogleMapsMarker) => void
}

export default class GoogleMapsMarker extends React.PureComponent<Props> {
  state = { opened: false }

  render() {
    const { line, setPrevious } = this.props

    return <Marker
      position={line.gps}
      icon={`https://lacloche.blueforest.fr/images/maps/${line.collection}.png`}
      onClick={() => {
        setPrevious(this)
        this.setState({ opened: true })
      }}
      children={this.state.opened && <InfoWindow children={hardcodedLaClocheHtml(line, false)}/>}
    />
  }

}
