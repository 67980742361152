import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import SidebarMenuItemC from "./SidebarMenuItem"
import SidebarMenuSection from "./SidebarMenuSection"
import SidebarMenuSubSection from "./SidebarMenuSubSection"

interface Props {
  className?: string
  items: Array<SidebarMenuItem>
}

interface State {
  itemSelected: number
  sectionSelected: number
  subSectionSelected: number
}

interface SidebarMenuItem extends SidebarMenuItemElement {
  sections: Array<SidebarMenuItemElement>
  subSections: Array<SidebarMenuItemElement>
}

interface SidebarMenuItemElement {
  name: string
}

export default class SidebarMenu extends WebComponent<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      itemSelected: 0,
      sectionSelected: -1,
      subSectionSelected: -1,
    }
  }

  handleClickOnItem(e, index) {
    e.stopPropagation()

    this.setState({
      itemSelected: index,
      sectionSelected: -1,
      subSectionSelected: -1,
    })
  }

  handleClickOnSection(e, itemIndex, sectionIndex) {
    e.stopPropagation()

    this.setState({
      itemSelected: itemIndex,
      sectionSelected: sectionIndex,
      subSectionSelected: -1,
    })
  }

  handleClickOnSubSection(e, itemIndex, sectionIndex, subSectionindex) {
    e.stopPropagation()

    this.setState({
      itemSelected: itemIndex,
      sectionSelected: sectionIndex,
      subSectionSelected: subSectionindex
    })
  }

  render() {
    const { className = "", items } = this.props

    return (
      <div className={ "sidebar__menu " + className }>
        { items.map((item, itemIndex) => {
            return (
              <SidebarMenuItemC 
                key={ itemIndex } 
                title={ item.name } 
                selected={ this.state.itemSelected === itemIndex } 
                clickHandler={ (e) => { this.handleClickOnItem(e, itemIndex) } }
              >
                { this.state.itemSelected === itemIndex 
                  &&
                  item.sections.map((section, sectionIndex) => {
                    return (
                      <SidebarMenuSection 
                        key={ sectionIndex } 
                        title={ section.name } 
                        selected={ 
                          this.state.itemSelected === itemIndex 
                          && this.state.sectionSelected === sectionIndex 
                        } 
                        clickHandler={ (e) => { this.handleClickOnSection(e, itemIndex, sectionIndex) } }
                      >
                        { this.state.sectionSelected === sectionIndex
                          &&
                          item.subSections.map((subSection, subSectionIndex) => {
                            return (
                              <SidebarMenuSubSection
                                key={ subSectionIndex }
                                title={ subSection.name }
                                selected={ 
                                  this.state.itemSelected === itemIndex 
                                  && this.state.sectionSelected === sectionIndex 
                                  && this.state.subSectionSelected === subSectionIndex
                                }
                                clickHandler={ (e) => { this.handleClickOnSubSection(e, itemIndex, sectionIndex, subSectionIndex) } }
                              />
                            )
                          })
                        }
                      </SidebarMenuSection>
                    )
                  })
                }
              </SidebarMenuItemC>
            )
          }) 
        }
      </div>
    )
  }
}
