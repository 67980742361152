import { onDevEnv, Client } from "kiwi-bundle"
import { DROPinAPI } from "dropin-recipes"
import router from "./router"
import "./main.scss"

// @ts-ignore-next-line
DROPinAPI.URL = "https://api.lacloche.blueforest.cc"

onDevEnv(() => {
  DROPinAPI.enableLocalMode(8040)
})

const CLIENT = new Client(router)

export default CLIENT
