import * as React from "react"
import { WebComponent } from "kiwi-bundle"

interface Props {
  selectorCell?: boolean
  heading?: boolean
  order?: boolean
  orderKey?: string
  handleOrder?: (orderKey: string) => void
}

interface State {

}

export default class ListCell extends WebComponent<Props, State> {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    const { heading = false, order = false, handleOrder, orderKey = "UNKNOWN_KEY", selectorCell = false } = this.props

    let classNames = ["list__cell"]
    if(heading) classNames.push("list__cell_heading")
    if(order) classNames.push("list__cell_order")
    if(selectorCell) classNames.push("list__cell_selector")

    if(order) {
      return <a
        className={ classNames.join(" ") }
        onClick={() => { if(handleOrder) handleOrder(orderKey) }}
        children={this.props.children}
      />
    }

    return <div className={classNames.join(" ")} children={this.props.children}/>
  }
}
