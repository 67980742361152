import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import Icon, { IconType } from "../Icon"
import { User } from "../../App"

import HeaderNavItem from "./HeaderNavItem"
import HeaderDropdownUser from "../Dropdown/HeaderDropdownUser"

interface Props {
  user: User,
  logoutHandler: () => void
}

export default class HeaderNavItemUser extends WebComponent<Props> {
  render() {
    const { user, logoutHandler } = this.props

    return (
      <HeaderNavItem hasDropdown={ true }>
        <a className="header__nav-link ">
          <Icon
            type={ IconType.user }
            className="header__nav-icon"
            width="22"
            height="22"
          />
        </a>

        <HeaderDropdownUser user={user} logoutHandler={ logoutHandler } />
      </HeaderNavItem>
    )
  }
}
