import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import ToolbarLink, { ToolbarLinkType } from "./ToolbarLink"

import "./Toolbar.scss"
import OutsideClickWrapper from "../../OutsideClickWrapper";

interface Props {
  className?: string
}

interface State {
  activeLink: ToolbarLinkType
}

export default class Toolbar extends WebComponent<Props, State> {
  state = {
    activeLink: null,
  }

  handleClickOnLink(type: ToolbarLinkType) {
    this.setState({ activeLink: type })
  }

  handleOutsideClick() {
    if(this.state.activeLink !== null) {
      this.setState({ activeLink: null })
    }
  }

  render() {
    const { className = "" } = this.props
    return (
      <OutsideClickWrapper outsideClickHandler={this.handleOutsideClick.bind(this)}>
        <div className={"toolbar " + className}>
          {(Object.values(ToolbarLinkType).map((type, index) => (
            <ToolbarLink
              key={`toolbar-link-${index}`}
              type={type}
              clickHandler={() => { this.handleClickOnLink(type) }}
              isActive={this.state.activeLink === type}
            />
          )))}
        </div>
      </OutsideClickWrapper>
    )
  }
}
