import { i18nData, Language as l  } from "dropin-recipes"

const loginStrings = i18nData({

  baseline: {
    [l.ENGLISH]: "A universe to shape your ideas",
    [l.FRENCH]: "Un univers pour façonner vos idées",
  },

  forgottenPassword: {
    [l.ENGLISH]: "Forgotten your password?",
    [l.FRENCH]: "Mot de passe oublié ?",
  },

  useSocials: {
    [l.ENGLISH]: "or use :",
    [l.FRENCH]: "ou utiliser :",
  },

  haveInvitationCode: {
    [l.ENGLISH]: "You have an invitation code?",
    [l.FRENCH]: "Vous possédez un code d'invitation ?",
  },

  createYourAccount: {
    [l.ENGLISH]: "Create you account !",
    [l.FRENCH]: "Créer votre compte !",
  },

})

export default loginStrings
