import * as React from "react"
import { WebComponent, observer } from "kiwi-bundle"
import { Company } from "dropin-recipes"

import SidebarMenu from "./SidebarMenu"
import OutsideClickWrapper from "../../OutsideClickWrapper"

import "./Sidebar.scss"

interface Props {
  store: CompaniesStore
}

interface State {
  headerOpen: boolean
  selectedCompany: string
}

const items = [
  { name: "Tableau de bord", sections: [{
    name: "Section 1"
  }], subSections: [{
    name:"Sous-section 1"
  }] },
  { name: "Commerce", sections: [], subSections: [] },
  { name: "Contacts", sections: [], subSections: [] },
]

export interface CompaniesStore {
  companies: Company[]
  setCompanies(companies: Company[]): void
}

@observer
export default class Sidebar extends WebComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      headerOpen: false,
      selectedCompany: null,
    }
  }

  handleClick(company?: string) {
    if(typeof company === "undefined" || company === this.state.selectedCompany) {
      this.setState((previousState) => ({
        headerOpen: !previousState.headerOpen,
      }))
    } else {
      this.setState({
        selectedCompany: company,
        headerOpen: false,
      })
    }
  }

  handleClickOutsideHeader() {
    if(this.state.headerOpen) {
      this.setState({ headerOpen: false })
    }
  }

  render() {
    const { store } = this.props

    return <nav className={ "sidebar " + (this.state.headerOpen ? "sidebar_open" : "") }>
      <OutsideClickWrapper outsideClickHandler={this.handleClickOutsideHeader.bind(this)}>
        <header className="sidebar__header" onClick={() => { this.handleClick() }}>
          <div className="sidebar__header-content">
            <ul className="sidebar__header-list">
              {store.companies.reduce((companies, company, index) => {
                const selected = this.state.selectedCompany !== null
                  ? this.state.selectedCompany === company.id 
                  : index === 0
                const children = <li
                  key={company.id}
                  className={"sidebar__header-list-item " + (selected ? "sidebar__header-list-item_active" : "") }
                  onClick={(event) => {
                    event.stopPropagation()
                    this.handleClick(company.id)
                  }}
                  children={company.name}
                />
                if(selected) {
                  companies.unshift(children)
                } else {
                  companies.push(children)
                }
                return companies
              }, [])}
            </ul>
          </div>
        </header>
        <SidebarMenu items={ items } />
      </OutsideClickWrapper>
    </nav>
  }
}
