import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import FormGroup from "./FormGroup"
import Button from "./Button"

interface Props {
  onClick?: (e) => void
}

export default class FormSubmitButton extends WebComponent<Props> {
  render() {
    const { onClick } = this.props

    return (
      <FormGroup>
        <Button className="btn_primary btn_block form__btn_block" type="submit" onClick={onClick}>
          { this.props.children }
        </Button>
      </FormGroup>
    )
  }
}
