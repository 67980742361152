import { React, WebPage, Link } from "kiwi-bundle"
import { DROPinAPI, i18n } from "dropin-recipes"

import AppSingleForm from "../components/unauthApp/AppSingleForm"
import Form from "../components/unauthApp/Form"

import InputEmail from "../components/unauthApp/InputEmail"
import InputPassword from "../components/unauthApp/InputPassword"

import FormSubmitButton from "../components/unauthApp/FormSubmitButton"
import Input from "../components/unauthApp/Input"

import FormGroup from "../components/unauthApp/FormGroup"
import Check from "../components/unauthApp/Check"

import FormError from "../components/unauthApp/FormError"

import URLParser from "../core/URLParser"
import Auth from "../core/Auth"

import AppStorage from "../core/AppStorage"
import { PAGES } from "../router"
import registerStrings from "../i18n/register"
import { authStore } from "../stores/auth"
import FormSeparator from "../components/unauthApp/FormSeparator"

import * as router from "../router"

enum Step {
  Register,
  RegisterSuccessful
}

class Register extends WebPage {
  state = {
    step: Step.Register,
    formError: null,
  }

  auth: Auth

  hash?: string
  email?: string

  constructor(props) {
    super(props)

    this.hash = URLParser.getQueryVariableFromWindowLocation("hash")
    this.email = URLParser.getQueryVariableFromWindowLocation("email")

    this.auth = new Auth(new AppStorage(localStorage))
  }

  componentDidMount() {
    super.componentDidMount()
    if(this.hash && this.email) {
      DROPinAPI.validateEmail(this.email, this.hash)
        .then(resp => {
          this.auth.setUserAsAuthenticated(resp.jwt, resp.user)
          this.props.history.push(PAGES.HOME)
        })
        .catch(err => {
          this.setState({
            formError: err.message
          })
        })
    }
  }

  formTitle() {
    return this.state.step === Step.Register
      ? i18n(registerStrings.step1)
      : i18n(registerStrings.step2)
  }

  handleSubmit(event) {
    event.preventDefault()
    DROPinAPI.register(authStore.invitationCode.get(), authStore.email.get(), authStore.password.get())
      .then(resp => {
        this.setState({
          formError: null,
          step: Step.RegisterSuccessful,
        })
      })
      .catch(err => {
        this.setState({
          formError: err.message,
        })
      })
  }

  render() {
    return (
      <AppSingleForm>
        <Form method="post" action={PAGES.REGISTER} title={this.formTitle()} titleClass="title_xs" onSubmit={(e) => {this.handleSubmit(e)}}>
          {this.state.step === Step.Register ? (
            <React.Fragment>
              <FormGroup>
                <Input
                  type="text"
                  name="invitation_code"
                  value={authStore.invitationCode}
                  onValueChange={invitationCode => { authStore.setInvitationCode(invitationCode) }}
                  placeholder={i18n(registerStrings.invitationCode)}
                />
              </FormGroup>

              <InputEmail
                value={authStore.email}
                onValueChange={email => { authStore.setEmail(email) }}
              />

              <InputPassword
                showRecoverPasswordLink={false}
                enablePasswordShowHelper={true}
                value={authStore.password}
                onValueChange={password => { authStore.setPassword(password) }}
              />

              <Check name="rgpd_checkbox" id="rgpd_checkbox" containerClass="form__check" required={true}>
                {i18n(registerStrings.terms)} (<a className="check__label-link" href="#">{i18n(registerStrings.GDPR)}</a>)
              </Check>

              {this.state.formError && <FormError className="form__error_under-check" children={this.state.formError}/>}

              <FormSubmitButton>{i18n(registerStrings.createMyAccount)}</FormSubmitButton>

              <FormSeparator />

              <p className="form__incentive">
                {i18n(registerStrings.haveAnAccount)}<br/>
                <Link className="form__incentive-link" action={router.default.getLinkAction(router.PAGES.LOGIN)}>
                {i18n(registerStrings.login)}
                </Link>
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h2 className="title title_xxs form__recover-password-title form__recover-password-title_first">
                {i18n(registerStrings.step3)}
              </h2>

              <p className="text text_primary text_xs form__text">
                {i18n(registerStrings.emailSent)} <strong>{authStore.email.get()}</strong>
              </p>

              <p className="text text_primary text_xs form__text">
                {i18n(registerStrings.emailDescription)}
              </p>

              <h2 className="title title_xxs form__mail-not-received-title">
                {i18n(registerStrings.emailNotReceived)}
              </h2>

              <FormSubmitButton>
                {i18n(registerStrings.emailResend)}
              </FormSubmitButton>
            </React.Fragment>
          )}
        </Form>
      </AppSingleForm>
    )
  }
}

export default Register
