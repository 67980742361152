import * as React from "react"
import { WebComponent } from "kiwi-bundle"

export default class FormSeparator extends WebComponent {
  render() {
    return (
      <hr className="form__separator"/>
    )
  }
}
