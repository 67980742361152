import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import Icon, { IconType } from "../Icon"
import HeaderNavItem from "./HeaderNavItem";

interface Props {

}

export default class HeaderNavItemSearch extends WebComponent<Props> {
  render() {
    return (
      <HeaderNavItem>
        <form className="header__form">
          <input className="header__search-input" type="search" placeholder="rechercher"/>
          <Icon type={ IconType.search } className="header__search-icon" width="22" height="22"/>
        </form>
      </HeaderNavItem>
    )
  }
}
