import { Router, Route } from "kiwi-bundle"
import Document from "./pages/Document"
import Home from "./pages/Home"
import Login from "./pages/Login"
import Register from "./pages/Register"
import RouteAuthentifier from "./core/RouteAuthentifier"
import Auth from "./core/Auth"
import AppStorage from "./core/AppStorage"

export enum PAGES {
  HOME = "/",
  SECTION = "/:type(u|c)/:id/:section",
  LOGIN = "/login",
  REGISTER = "/register",
  DOCUMENT = "/document/:recipe/:document",
}

export default new Router([
  new Route([ PAGES.HOME, PAGES.SECTION ], Home, { authLevels: [ "user" ] }),
  new Route(PAGES.LOGIN, Login, { authLevels: [ "anonymous" ] }),
  new Route(PAGES.REGISTER, Register, { authLevels: [ "anonymous" ] }),
  new Route(PAGES.DOCUMENT, Document),
], {
  routeAuthentifier: new RouteAuthentifier(new Auth(new AppStorage(localStorage))),
})
