import * as React from "react"
import { WebComponent, IObservableValue } from "kiwi-bundle"
import { i18n } from "dropin-recipes"

import FormGroup from "./FormGroup"
import Input from "./Input"
import userStrings from "../../i18n/user"

interface Props {
  value: IObservableValue<string>
  onValueChange?: (value: string) => void
}

export default class InputEmail extends WebComponent<Props> {
  render() {
    const { value, onValueChange } = this.props
    return (
      <FormGroup>
        <Input
          type="email"
          name="email"
          value={value}
          onValueChange={onValueChange}
          placeholder={i18n(userStrings.email)}
        />
      </FormGroup>
    )
  }
}
