import { observable, action } from "kiwi-bundle"

class AuthStore {
  email = observable.box("")
  password = observable.box("")
  invitationCode = observable.box("")

  @action
  setEmail(email: string) {
    this.email.set(email)
  }

  @action
  setPassword(password: string) {
    this.password.set(password)
  }

  @action
  setInvitationCode(invitationCode: string) {
    this.invitationCode.set(invitationCode)
  }
}

export const authStore = new AuthStore()
