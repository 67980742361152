import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import { User } from "../../App"

import Dropdown from "./Dropdown"
import UserElement from "./UserElement"

interface Props {
  user: User,
  logoutHandler: () => void
}

interface HeaderDropdownUserItem {
  title: string,
  handler: (e) => void,
}

export default class HeaderDropdownUser extends WebComponent<Props> {
  items: Array<HeaderDropdownUserItem>

  constructor(props) {
    super(props)

    this.items = [{
      title: "Paramètres",
      handler: this.handleClickOnParameters
    },{
      title: "Déconnexion",
      handler: this.handleClickOnLogout,
    }]
  }

  handleClickOnParameters(e) {
    console.log("parameters")
  }

  handleClickOnLogout(e) {
    this.props.logoutHandler()
  }

  render() {
    const { user } = this.props

    return (
      <Dropdown className="dropdown_right header__dropdown">
        <div className="header__dropdown-user-header">
          <UserElement text={ user.name } />
        </div>

        <ul className="dropdown__list">
          { this.items.map((item) => {
            return (
              <li className="dropdown__list-item dropdown__list-item_borderless" key={ item.title }>
                <a
                  className="dropdown__list-item-text dropdown__list-item-link"
                  onClick={ (e) => { item.handler.call(this, e) }}>
                  { item.title }
                </a>
              </li>
            )
          }) }
        </ul>
      </Dropdown>
    )
  }
}
