import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import { AppNotification, User } from "../App"

import HeaderNavItemSearch from "./HeaderNavItem/HeaderNavItemSearch"
import HeaderNavItemNotifs from "./HeaderNavItem/HeaderNavItemNotifs"
import HeaderNavItemUser from "./HeaderNavItem/HeaderNavItemUser"

import "./Header.scss"

interface Props {
  hasNewNotifications: boolean
  notifications: Array<AppNotification>
  user: User,
  logoutHandler: () => void
}

export default class Header extends WebComponent<Props> {
  render() {
    const { hasNewNotifications, notifications, user, logoutHandler } = this.props

    return (
      <header className="header">
        <ul className="header__nav">
          <HeaderNavItemSearch />
        </ul>

        <ul className="header__nav header__nav_right">
          <HeaderNavItemNotifs hasNew={ hasNewNotifications } notifications={ notifications } />
          <HeaderNavItemUser user={ user } logoutHandler={ logoutHandler } />
        </ul>
      </header>
    )
  }
}
