import { React, WebComponent, observer } from "kiwi-bundle"
import { GoogleMap } from "react-google-maps"
import * as Recipes from "dropin-recipes"
import router from "../router"
import GoogleMapsInteractive from "./GoogleMapsInteractive"
import GoogleMapsPrintable from "./GoogleMapsPrintable"
import { linesStore } from "../stores/lines"

interface GoogleMapsParams {
  map_allow_zoom?: boolean
}

interface Props {
  options: Recipes.GoogleMapsDocumentOptions
  defineActionOnPrint?: (action: () => void) => void
}

@observer
export default class GoogleMaps extends WebComponent<Props> {
  currentInteractiveMap: GoogleMap = null
  params: GoogleMapsParams = {}
  options: google.maps.MapOptions = {
    // disableDefaultUI: true,
  }

  constructor(props: Props) {
    super(props)

    // Parameters
    this.params = router.getParametersAsObject("map_")

    // Style
    if(typeof props.options.style !== "undefined") {
      this.options.styles = props.options.style
    }

    // Gestures
    if(typeof this.params.map_allow_zoom !== "undefined" && this.params.map_allow_zoom[0] === "1") {
      this.options.gestureHandling = "greedy"
    }

    // Click handler
    document.addEventListener("click", () => { window.parent.postMessage("click", "*") }, true)
  }

  render() {
    const { options, defineActionOnPrint } = this.props
    return [

      <GoogleMapsInteractive
        key="interactive-map"
        options={this.options}
        bounds={options.bounds}
        lines={linesStore.items}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${options.apiKey}`}
        loadingElement={<div style={{ height: "100%", }}/>}
        containerElement={<div style={{ height: "100%", }}/>}
        mapElement={<div id="interactive-map" style={{ height: "100%", }}/>}
        setInteractiveMap={map => { this.currentInteractiveMap = map }}
      />,

      <GoogleMapsPrintable
        key="printable-map"
        apiKey={options.apiKey}
        defineActionOnPrint={defineActionOnPrint}
        getCurrentInteractiveMap={() => this.currentInteractiveMap}
        style={options.style}
      />,

    ]
  }
}
