import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import Dropdown from "./Dropdown"

interface Props {

}

export default class ToolbarDropdown extends WebComponent<Props> {
  render() {
    return (
      <Dropdown className="toolbar__dropdown">
        { this.props.children }
      </Dropdown>
    )
  }
}
