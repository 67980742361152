import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import "./styles/Icon.scss"

interface Props {
  type: string
  size: string
}

export default class Icon extends WebComponent<Props> {
  render() {
    const { size, type } = this.props
    return <i className={"icon icon_" + type + " icon_" + size}></i>
  }
}
