import { React, ReactDOM, WebComponent } from "kiwi-bundle"

import { AppNotification } from "../../App"

import Dropdown from "./Dropdown"
import UserElement from "./UserElement"

interface Props {
  notifications: AppNotification[]
}

export default class HeaderDropdownNotifs extends WebComponent<Props> {

  handleScroll(event: React.UIEvent<HTMLDivElement> & any) {
    let scrollBottom = event.target.offsetHeight + event.target.scrollTop

    // Top elements
    const computed = window.getComputedStyle(event.target)
    scrollBottom -= parseFloat(computed.paddingTop)
    scrollBottom -= parseFloat(computed.marginTop)

    // Elements heights
    let heights = 0
    const elements = event.target.querySelectorAll("li")
    for(let index = 0; index < elements.length; index++) {
      heights += elements[index].offsetHeight
      if(heights < scrollBottom) {
        console.log("Notification", this.props.notifications[index].id, "seen")
      } else {
        break
      }
    }
  }

  render() {
    const { notifications } = this.props

    let listNotifications = notifications.map((notification, index) => {
      return <li
        key={index}
        className="dropdown__list-item"
        children={<a className="dropdown__list-item-link">
          <p className="dropdown__list-item-text" children={notification.content}/>
          <UserElement text={notification.author}/>
        </a>}
      />
    })

    return <Dropdown
      className="dropdown_right dropdown_scrollable header__dropdown"
      onScroll={this.handleScroll.bind(this)}
      children={<ul className="dropdown__list" children={listNotifications}/>}
    />
  }
}
