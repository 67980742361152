import * as React from "react"
import { WebComponent } from "kiwi-bundle"

interface Props {
  className?: string
}

export default class FormGroup extends WebComponent<Props> {
  render() {
    const { className = "" } = this.props

    return (
      <div className={"form__group " + className}>
        { this.props.children }
      </div>
    )
  }
}
