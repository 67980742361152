import { React, WebComponent } from "kiwi-bundle"
import { DROPinAPI } from "dropin-recipes"

import Form from "./Form"
import InputEmail from "./InputEmail"

import FormError from "./FormError"
import FormSubmitButton from "./FormSubmitButton"

import InputPassword from "./InputPassword"

import Auth from "../../core/Auth"
import AppStorage from "../../core/AppStorage"

import { PAGES } from "../../router"
import { authStore } from "../../stores/auth"

interface Props {
  hash?: string
  onChange?: (e) => void
  history: any
}

interface State {
  step: Step
  email?: string
  password?: string
  hash?: string
  formError?: string
}

enum Step {
  Email,
  EmailSuccess,
  Password,
  PasswordSuccess
}

class RecoverPasswordForm extends WebComponent<Props, State> {
  auth: Auth

  constructor(props) {
    super(props)

    let step = Step.Email
    if(props.hash) {
      step = Step.Password
    }

    this.state = {
      step: step,
      hash: props.hash
    }

    this.auth = new Auth(new AppStorage(localStorage))
  }

  formTitle() {
    if(this.state.step === Step.Email) {
      return "Veuillez saisir votre e-mail"
    }

    if(this.state.step === Step.EmailSuccess) {
      return "Nous venons de vous envoyer un e-mail !"
    }

    return "Veuillez saisir votre nouveau mot de passe"
  }

  formSubmitBtnTitle() {
    if(this.state.step === Step.Email) {
      return "Envoyer"
    }

    if(this.state.step === Step.EmailSuccess) {
      return "Renvoyer l'e-mail de réinitialisation"
    }

    return "Modifier mon mot de passe"
  }

  formContent() {
    if(this.state.step === Step.Email) {
      return <InputEmail
        value={authStore.email}
        onValueChange={email => { authStore.setEmail(email) }}
      />
    }

    if(this.state.step === Step.EmailSuccess) {
      return (
        <React.Fragment>
          <p className="text text_primary text_xs form__text form__text_first">
            Veuillez consulter votre boîte mail, vous trouverez un message de notre part
            contenant le lien pour <strong>réinitialiser votre mot de passe</strong>.
          </p>
          <h2 className="title title_xxs form__mail-not-received-title">Toujours pas reçu ?</h2>
        </React.Fragment>
      )
    }
    return <InputPassword
      value={authStore.email}
      onValueChange={email => { authStore.setEmail(email) }}
    />
  }

  handleSubmit(event) {
    event.preventDefault()

    if(this.state.step !== Step.Password) {
      DROPinAPI.forgottenPassword(this.state.email, this.state.hash).then(response => {
        this.setState({ formError: undefined })

        if(this.state.step === Step.Email) {
          this.setState({ step: Step.EmailSuccess })
        }
      }).catch(err => {
        this.setState({ formError: err.message })
      })
    } else {
      DROPinAPI.forgottenPasswordUpdate(this.state.email, this.state.hash, this.state.password).then(response => {
        this.setState({ step: Step.PasswordSuccess })
        this.auth.setUserAsAuthenticated(response.jwt, response.user)
        this.props.history.push(PAGES.HOME)
      }).catch(err => {
        this.setState({ formError: err.message })
      })
    }
  }

  render() {
    return (
      <Form method="post" action={PAGES.LOGIN} title={this.formTitle()} titleClass="title_xs" onSubmit={this.handleSubmit}>
        {this.formContent()}

        {this.state.formError &&
          <FormError>
            { this.state.formError }
          </FormError>
        }
        <FormSubmitButton>{this.formSubmitBtnTitle()}</FormSubmitButton>
      </Form>
    )
  }
}

export default RecoverPasswordForm
