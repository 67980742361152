import * as React from "react"
import { WebComponent, observer, action, IObservableValue } from "kiwi-bundle"

interface Props {
  type: string
  name: string
  value: IObservableValue<string>
  onValueChange?: (value: string) => void
  placeholder?: string
  required?: boolean
}

@observer
export default class Input extends WebComponent<Props> {
  render() {
    const { type, name, value, onValueChange, placeholder, required = true } = this.props
    return <input
      type={type}
      name={name}
      value={value.get()}
      onChange={event => {
        if(typeof onValueChange !== "undefined") {
          onValueChange(event.target.value)
        }
      }}
      placeholder={placeholder}
      className="form__control"
      required={required}
    />
  }
}
