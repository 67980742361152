import AppStorage from "./AppStorage"

class Auth {

  private _storage: AppStorage

  constructor(storage: AppStorage) {
    this._storage = storage
  }

  setUserAsAuthenticated(token: string, user: string) {
    this._storage.setItem("token", token)
    this._storage.setItem("user", user)
  }

  unauthenticateUser() {
    this._storage.removeItem("token")
    this._storage.removeItem("user")
  }

  isUserAuthenticated() {
    return this._storage.hasItem("token") && this._storage.hasItem("user")
  }

  getUser() {
    return this._storage.getItem("user")
  }

  getToken() {
    return this._storage.getItem("token")
  }

}

export default Auth
