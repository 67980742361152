import { React, WebPage, Link } from "kiwi-bundle"
import { DROPinAPI, i18n } from "dropin-recipes"

import URLParser from "../core/URLParser"
import Auth from "../core/Auth"
import AppStorage from "../core/AppStorage"
import loginStrings from "../i18n/login"
import * as router from "../router"

import AppSingleForm from "../components/unauthApp/AppSingleForm"
import Form from "../components/unauthApp/Form"

import InputEmail from "../components/unauthApp/InputEmail"
import InputPassword from "../components/unauthApp/InputPassword"

import FormSubmitButton from "../components/unauthApp/FormSubmitButton"
import FormSocialLogin from "../components/unauthApp/FormSocialLogin"

import FormSeparator from "../components/unauthApp/FormSeparator"
import FormError from "../components/unauthApp/FormError"

import RecoverPasswordForm from "../components/unauthApp/RecoverPasswordForm"
import userStrings from "../i18n/user"
import { authStore } from "../stores/auth"

enum Page {
  Login,
  RecoverPassword
}

class Login extends WebPage {
  state = {
    pageShown: Page.Login,
    loginError: null,
  }

  auth: Auth

  hash?: string

  constructor(props) {
    super(props)

    let hash = URLParser.getQueryVariableFromWindowLocation("hash")
    if(hash) {
      this.state.pageShown = Page.RecoverPassword
    }

    if(props.location.state && props.location.state.unauthRedirect) {
      const history = this.props.history
      const state = { ...history.location.state }

      delete state.unauthRedirect

      history.replace({ ...history.location, state })

      this.state.loginError = "Vous devez-vous connecter pour accéder à cette page."
    }

    this.auth = new Auth(new AppStorage(localStorage))

    this.hash = hash
  }

  handleClickOnRecoverPasswordLink() {
    this.setState({
      pageShown: Page.RecoverPassword
    })
  }

  handleLoginSubmit(event) {
    event.preventDefault()
    DROPinAPI.login(authStore.email.get(), authStore.password.get())
      .then(resp => {
        this.auth.setUserAsAuthenticated(resp.token.jwt, resp.user.id)
        this.props.history.push(router.PAGES.HOME)
      })
      .catch(err => {
        this.setState({
          loginError: err.message
        })
      })
  }

  loginForm() {
    return (
      <Form method="post" action={router.PAGES.LOGIN} title={i18n(loginStrings.baseline)} onSubmit={this.handleLoginSubmit.bind(this)}>
        <InputEmail
          value={authStore.email}
          onValueChange={email => { authStore.setEmail(email) }}
        />

        <InputPassword
          showRecoverPasswordLink={!this.state.loginError}
          recoverPasswordLinkClickHandler={this.handleClickOnRecoverPasswordLink}
          value={authStore.password}
          onValueChange={password => { authStore.setPassword(password) }}
        />

        {this.state.loginError && <FormError children={this.state.loginError}/>}

        <FormSubmitButton>{i18n(userStrings.login)}</FormSubmitButton>

        <FormSocialLogin/>

        <FormSeparator/>

        <p className="form__incentive">
          {i18n(loginStrings.haveInvitationCode)}<br/>
          <Link className="form__incentive-link" action={router.default.getLinkAction(router.PAGES.REGISTER)}>
          {i18n(loginStrings.createYourAccount)}
          </Link>
        </p>
      </Form>
    )
  }

  recoverPasswordForm() {
    return <RecoverPasswordForm hash={this.hash} history={this.props.history}/>
  }

  render() {
    let pageToDisplay = this.loginForm()

    if(this.state.pageShown === Page.RecoverPassword) {
      pageToDisplay = this.recoverPasswordForm()
    }

    return (
      <AppSingleForm>
        {pageToDisplay}
      </AppSingleForm>
    )
  }
}

export default Login
