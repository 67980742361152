import * as React from "react"
import { WebComponent } from "kiwi-bundle"

interface Props {
  shadowed?: boolean
}

interface State {

}

export default class ListRow extends WebComponent<Props, State> {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    const { shadowed = false } = this.props

    return (
      <div className={ "list__row " + (shadowed ? "list__row_shadowed" : "") }>
        { this.props.children }
      </div>
    )
  }
}
