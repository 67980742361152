import * as React from "react"
import { WebComponent } from "kiwi-bundle"

export enum IconType {
  search = "search",
  notification = "notification",
  user = "user",
  group = "group",
  toolbarCalendar = "toolbarCalendar",
  toolbarGrille = "toolbarGrille",
  toolbarList = "toolbarList",
}

interface Props {
  type: IconType
  className?: string
  width?: string
  height?: string
  alt?: string
}

const groupLogo = require("../../../assets/group.svg")
const searchIcon = require("../../../assets/search.svg")

const userIcon = require("../../../assets/user.svg")
const notificationIcon = require("../../../assets/bell.svg")

const toolbarCalendar = require("../../../assets/toolbar/calendar.svg")
const toolbarGrille = require("../../../assets/toolbar/grille.svg")

const toolbarList = require("../../../assets/toolbar/list.svg")

export default class Icon extends WebComponent<Props> {
  render() {
    const { className = "", type, width, height, alt = "" } = this.props
    let src = groupLogo

    if(type === IconType.search) {
      src = searchIcon
    }

    if(type === IconType.notification) {
      src = notificationIcon
    }

    if(type === IconType.user) {
      src = userIcon
    }

    if(type === IconType.toolbarCalendar) {
      src = toolbarCalendar
    }

    if(type === IconType.toolbarGrille) {
      src = toolbarGrille
    }

    if(type === IconType.toolbarList) {
      src = toolbarList
    }

    return <img
      className={"icon " + className}
      style={{ width: width + "px", height: height + "px", }}
      src={src}
      alt={alt}
    />
  }
}
