import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import "./styles/Button.scss"

interface Props {
  type: "button"|"submit"|"reset"
  className: string
  onClick?: (e) => void
}

export default class Button extends WebComponent<Props> {
  render() {
    const { type, onClick, className } = this.props

    return (
      <button className={"btn " + className} type={type} onClick={onClick}>
        {this.props.children}
      </button>
    )
  }
}
