import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import GridToolbarDropdown from "../Dropdown/GridToolbarDropdown"

import "./Toolbar.scss"

export enum ToolbarLinkType {
  case = "case",
  add = "add",
  grid = "grid",
  filter = "filter",
  login = "login",
  externalLink = "external-link",
}

interface Props {
  type: ToolbarLinkType,
  clickHandler?: (e) => void,
  isActive?: boolean
}

interface State {

}

export default class ToolbarLink extends WebComponent<Props, State> {
  getDropdownForType(type: ToolbarLinkType) {
    if(type === ToolbarLinkType.grid) {
      return <GridToolbarDropdown />
    }

    return null
  }

  render() {
    const { type, isActive = false } = this.props

    return <div
      className={ "toolbar__link toolbar__link_" + type.toString() + " " + (isActive ? "toolbar__link_active" : "") }
      onClick={this.props.clickHandler.bind(this)}
      children={this.getDropdownForType(type)}
    />
  }
}
