import { Company as CompanyInterface } from "dropin-recipes"

import BaseEntity from "./BaseEntity"

class Company extends BaseEntity implements CompanyInterface {
  type: "Company"
  
  name: string
  slug: string

  constructor(id: string, name: string, slug: string, createdAt: Date, updatedAt: Date) {
    super(id, createdAt, updatedAt)

    this.name = name
    this.slug = slug
  }
}

export default Company
