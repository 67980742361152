import { React } from "kiwi-bundle"
import "./Dropdown.scss"

interface Props {
  className?: string
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void
}

export default class Dropdown extends React.PureComponent<Props> {
  render() {
    const { className = "", onScroll } = this.props
    return (
      <div className={ "dropdown " + className } onScroll={onScroll}>
        <div className="dropdown__content" children={this.props.children}/>
      </div>
    )
  }
}
