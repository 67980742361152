import * as React from "react"
import { withScriptjs, withGoogleMap, GoogleMap, WithGoogleMapProps, WithScriptjsProps } from "react-google-maps"
import GoogleMapsMarker from "./GoogleMapsMarker"

type GoogleMapsType = React.ComponentClass<WithGoogleMapProps & WithScriptjsProps & {
  options: google.maps.MapOptions
  bounds: google.maps.LatLngBounds
  lines: any[]
  setInteractiveMap: (map: GoogleMap) => void
}, {}>

const GoogleMapsInteractive: GoogleMapsType = withScriptjs(withGoogleMap(props => {
  const linesBounds = new google.maps.LatLngBounds()
  let previous: GoogleMapsMarker = null
  let loaded = 0

  const markers = props.lines.map(line => {
    linesBounds.extend(line.gps)
    return <GoogleMapsMarker
      key={line.collection + line.id}
      line={line}
      setPrevious={value => {
        if(previous !== null && previous.state.opened) {
          previous.setState({ opened: false })
        }
        previous = value
      }}
    />
  })

  return <GoogleMap
    defaultZoom={6}
    options={props.options}
    ref={map => {
      props.setInteractiveMap(map)
      if(map !== null && loaded < 2) {
        if(markers.length > 0) {
          map.fitBounds(linesBounds)
          map.panToBounds(linesBounds)
          loaded = 2
        } else if(loaded === 0 && Object.keys(props.bounds).length > 0) {
          map.fitBounds(props.bounds)
          map.panToBounds(props.bounds)
          loaded = 1
        }
      }
    }}
    children={markers}
  />
}))

export default GoogleMapsInteractive
