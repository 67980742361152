import { Route } from "kiwi-bundle"
import Auth from "./Auth"
import { PAGES } from "../router"

class RouteAuthentifier {
  auth: Auth

  constructor(auth: Auth) {
    this.auth = auth
  }

  currentUserAuthLevel() {
    return this.auth.isUserAuthenticated() ? "user" : "anonymous"
  }

  unauthRedirectPathForRoute(route: Route) {
    if(route.options.authLevels.includes("anonymous")) {
      return PAGES.HOME
    }

    return PAGES.LOGIN
  }

  currentUserHasAccessToRoute(route: Route) {
    if(!route.options.authLevels
      || route.options.authLevels.includes(this.currentUserAuthLevel())) {
      
      return true
    }

    return false
  }
}

export default RouteAuthentifier
