import * as React from "react"
import { WebPage } from "kiwi-bundle"
import { UserId, CompanyId, CollectionRecipe, RecipeType, FieldType } from "dropin-recipes"

import App from "../components/App"
import Toolbar from "../components/authApp/Toolbar/Toolbar"

import List, { ListHeadingItem } from "../components/authApp/List/List"

import "./Home.scss"
import { appStore } from "../stores/app"
import { linesStore } from "../stores/lines"

const fakeCollectionRecipe: CollectionRecipe = {
  apiVersion: 1,
  type: RecipeType.COLLECTION,
  id: "commerces",
  fields: {
    name: { name: "Nom", type: FieldType.NAME },
    type: { name: "Type", type: FieldType.TYPE },
    schedules: { name: "Horaires", type: FieldType.TEXT },
    status: { name: "Statut", type: FieldType.PIPELINE },
    city: { name: "Ville", type: FieldType.TEXT },
    sector: { name: "Secteur", type: FieldType.COLLECTION },
  },
}

const fakeLines = [
  { id: "1", data: { name: "Test 1", type: "Bar", schedules: "13h-14h", status: "Ajouté", city: "Marseille", sector: "A" } },
  { id: "2", data: { name: "Test 2", type: "Boutique", schedules: "16h-21h", status: "Ajouté", city: "Lyon", sector: "C" } },
  { id: "3", data: { name: "Test 3", type: "Vétérinaire", schedules: "10h-12h", status: "Retiré", city: "Paris", sector: "B" } },
]

const fakeSection = [ "name", "type", "status", "city" ]

interface Params {
  type: "u"|"c"
  id: UserId|CompanyId
  section: string
}

export default class Home extends WebPage<Params> {
  listHeadingItems: Array<ListHeadingItem>

  constructor(props) {
    super(props)

    if(this.params.type === "c") {
      appStore.company = this.params.id
      appStore.section = this.params.section
    }

    linesStore.setFields(fakeCollectionRecipe.fields)
    linesStore.setItems(fakeLines)
  }

  render() {
    return (
      <App history={this.props.history}>
        <Toolbar className="app__content-toolbar"/>
        <List
          className="app__content-list"
          columns={fakeSection}
          selectable={true}
        />
      </App>
    )
  }
}
