import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import Header from "./Header"
import Footer from "./Footer"

import "./styles/App.scss"

export default class AppSingleForm extends WebComponent {
  render() {
    return (
      <React.Fragment>
        <div className="container-vc-wrapper">
          <div className="container container_xs">
            <Header/>
            <main className="row row_centered">
              <div className="col col_xs" children={this.props.children}/>
            </main>
          </div>
        </div>
        <Footer/>
      </React.Fragment>
    )
  }
}
