import { observable, action } from "kiwi-bundle"

import { CompaniesStore } from "../components/authApp/Sidebar/Sidebar"
import Company from "../core/Company"


class AppStore implements CompaniesStore {
  @observable companies: Company[] = []

  @observable company?: string
  @observable section?: string

  @action
  setCompanies(companies: Company[]) {
    this.companies = companies
  }
}

export const appStore = new AppStore()
