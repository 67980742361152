import * as React from "react"
import { WebComponent } from "kiwi-bundle"

import "./styles/Check.scss"

interface Props {
  id: string
  name: string
  containerClass?: string
  required?: boolean
}

export default class Checkbox extends WebComponent<Props> {
  render() {
    const { id, name, containerClass = "", required = false } = this.props

    return (
      <div className={"check " + containerClass}>
        <input className="check__input" type="checkbox" value="" name={name} id={id} required={required}/>
        <label className="check__label" htmlFor={id} children={this.props.children}/>
      </div>
    )
  }
}
